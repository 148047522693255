import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languages = ['en', 'ru'];



i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        whitelist: languages,
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/translation.json`
        },
        interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        }
    });
export default i18n;